
.CardsListSlickSlider {
	display: block;
	flex: 1;

	> div {
		display: block;
	}

	.slick-slider {

		height: 100%;
		
		.slick-arrow {
			display: none !important;
		}
	}

	.CardsSlick {
		padding: 0 15px;

		.cardTitle {
			font-size: 46px;
			margin-bottom: 20px;
			font-family: "Philosopher", sans-serif;
			font-weight: 400;
			font-style: normal;
			color: white;
			text-shadow: 0 0 5px black;
			padding: 0 15px;
		}

		.CardsSlick_block {
			display: flex;

			.Cards_image {
				// width: 50%;

				&.rotate-container {
					position: relative;
				
					.cardImage {	
						// padding-top: 35px;
						height: auto;

						img {
							max-width: 300px;
							height: auto;
							margin: 0 auto;
							display: block;
						}
					}
					.card-front {
						width: 100%;
						height: 100%;
						transform: perspective(600px) rotateY(0deg);
						backface-visibility: hidden;
						transition: all .5s linear 0s;
				
						.cardImage {							
							img {
								border-radius: 15px;
								border: 1px solid #c69a4f;
							}
						}
					}
				
					.card-back {
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 100%;
						height: 100%;
						transform: perspective(600px) rotateY(0deg);
						backface-visibility: hidden;
						transition: all .5s linear 0s;
						
						transform: perspective(1600px) rotateY(180deg);
						position: absolute;
						top: 0;
						left: 0;
						right: 0;

						.cardImage {
							background-color: white;
							padding: 20px;
							border-radius: 10px;
							
							img {
								max-width: 280px;
								border: 1px solid black;
							}
						}						
					}
				
					.rotate-card-front {
						transform: perspective(1600px) rotateY(-180deg)
					}
				
					.rotate-card-back {
						transform: perspective(1600px) rotateY(0deg)
					}
				}
			}

			.Card_coontent {
				// width: 50%;
				text-align: left;

				.CardDescription {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 20px;
				}
				.cardConclusion {
					font-size: 24px;
					font-family: "Inter", sans-serif;
					font-weight: 300;
				}
			}
		}

		.tag_word {
			background: #e1e1e1;
			color: black;
			text-transform: uppercase;
			text-shadow: none;
			font-size: 16px;
			border-radius: 25px;
			padding: 5px 10px;
			margin: 0 10px 10px 0;
		}

		&.conclusion {
			font-size: 46px;
			font-family: "Philosopher", sans-serif;
			font-weight: 400;
			font-style: normal;
			color: white;
			text-shadow: 0 0 5px black;
			padding: 0 15px;
			max-width: 80%;

			.general_conclusion {
				font-size: 36px;
			}
		}
	}
	
	.slide-number {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 25px;
		bottom: 75px;

		.btn_arrow {
			background: none;
			border: none;

			.arrow_img {
				width: 65px;
				height: 65px;
			}
		}

		.btn_prev {
			transform: rotate(90deg);
		}

		.btn_next {
			transform: rotate(-90deg);
		}

		.numbers {
			width: 80px;
			text-align: right;
			font-size: 26px;
			font-weight: 200;
			font-family: "Philosopher", sans-serif;

			.current_number {
				display: inline-block;
				width: 40px;
				padding-right: 5px;

				font-size: 52px;
				font-weight: 300;
			}
		}
	}
}


// @media (max-width: 576px) {
@media (max-width: 767px) {
	.popup-box > .container >.row {
		display: flex;
		flex-direction: column;

		.hide-cards {
			right: 15px;
			top: -85px;
			padding: 10px 14px;
			font-size: 14px;
		}
	}

	.popup-wrap {
		.close-btn {
			right: 25px;
			top: -75px;
			width: 25px;
			height: 25px;
		}
	}

	.CardsListSlickSlider {
		position: relative;
		max-height: calc(100vh - 90px);

		.slick-slider {
			height: 100%;
			
			.slick-list {
				height: 100%;
				
				.slick-track {
					height: 100%;

					.slick-slide {
						> div {
							height: 100%;

							.CardsSlick {
								padding: 0 15px;
								display: flex;
								flex-direction: column;
								height: 100%;
					
								.cardTitle {
									font-size: 32px;
									padding: 15px 60px 0 15px;
									margin-bottom: 0;
									text-align: left;
									line-height: 32px;
									height: 90px;
								}
					
								.CardsSlick_block {
									flex: 1 0 auto;
									display: flex;
									max-height: calc(100% - 90px);
									// flex-direction: column;
									// max-height: calc(100vh - 250px);
			
									.Cards_image {
										flex: 1 0 auto;
										height: calc(60vh - 200px);
			
										.card-front,
										.card-back {
											bottom: 0;
											max-height: 100%;
											min-height: 100%;

											.cardImage {
												position: relative;
												height: -webkit-fill-available;
												max-height: 100%;

												img {
													border: 1px solid black;
													// max-width: 100%;
													height: inherit;
													width: auto;
													max-height: 100%;
												}
											}
										}

										.card-back {
											// height: calc(100% - 20px);
											// background: white;
											// border-radius: 10px;
										}
					
										&.rotate-container {
											position: relative;
										
											.cardImage {	
												// padding-top: 35px;
												// height: auto;
					
												img {
													// max-width: 300px;
													// max-height: 100%;
												}
											}
											.card-front {
												
										
												.cardImage {							
													img {
														// border-radius: 15px;
														// border: 1px solid #c69a4f;
													}
												}
											}
										
											.card-back {
					
												.cardImage {
													background-color: white;
													padding: 7px;
													border-radius: 7px;
													height: fit-content;
													
													img {
														// max-width: 120px;
														border: 1px solid black;
														max-width: 100%;
														height: auto;
													}
												}						
											}
										
										}
									}
					
									.Card_coontent {
										text-align: left;
										max-height: 50%;
										overflow: hidden;
										scroll-behavior: auto;
										overflow-y: auto;
										// padding-top: 20px;
					
										.cardConclusion {
											font-size: 20px;
										}
									}
								}

								.CardDescription {
									margin-bottom: 10px;
									text-align: left;
									display: flex;
									flex-wrap: wrap;
			
									.tag_word {
										font-size: 14px;
										border-radius: 25px;
										padding: 4px 8px;
										margin: 0 5px 5px 0;
									}
								}
					
								&.conclusion {
									padding: 0;
									font-size: 32px;
									min-width: 100%;
								}
							}
						}
					}
				}
			}
		}

		

		.slide-number {
			position: absolute;
			bottom: -90px;
	
			.btn_arrow {
				.arrow_img {}
			}
	
			.btn_prev {
			}
	
			.btn_next {
			}
	
			.numbers {
				.current_number {}
			}
		}

		.CardsSlick.conclusion .general_conclusion {
			font-size: 24px;
			max-height: calc(100vh - 200px);
			overflow: hidden;
			overflow-y: auto;
			text-align: left;
			padding: 0 25px 0 10px;
		}
	}

	.cards_footer {
		.Separator {
			padding-top: 0;
		}
		.button_block {
			float: left;
			font-size: 24px;
			padding-right: 210px;
		}
	}
}

@media (min-width: 576px) and (max-width: 767px) {
	.CardsListSlickSlider .slick-slider .slick-list .slick-track .slick-slide > div .CardsSlick {

		.CardDescription {
			.tag_word {
				margin: 0 10px 10px 0;
			}
		}

		.CardsSlick_block .Card_coontent {
			max-height: 100%;
		}
	}
}
@media (max-width: 767px) and (max-height: 400px) {
.CardsListSlickSlider .slick-slider .slick-list .slick-track .slick-slide > div .CardsSlick .CardsSlick_block .Cards_image {
		flex: 1 0 auto;
		height: calc(100vh - 90px);
	}
}