.TaroBg {
	position: fixed;
    width: 100%;
    height: 100%;

	background: #558ea8;
	// background: radial-gradient(circle, #d0cca2 0%, rgba(1, 104, 143, 0) 15%, #558EA8 100%);
	background: radial-gradient(farthest-corner at 50% 0%, #c5c3ac 0%, #356b8d 65%);
	// background: radial-gradient(farthest-corner at 50% 0%, #334546 0%, #e5ddab 80%);
	
	> div {
		position: absolute;
	}
	
	//left
	.TaroBg-left-bottom {
		width: 528px;
		height: 402px;
		left: 0;
		bottom: 0;
	}

	.TaroBg-left-column {
		width: 52px;
		height: 100%;
		top: 0;
		left: 0;
	}

	.TaroBg-left-top {
		width: 340px;
		height: 395px;
		top: 0;
		left: 0;
	}

	// right
	.TaroBg-right-bottom {
		width: 527px;
		height: 411px;
		right: 0;
		bottom: 0;
	}

	.TaroBg-right-top {
		width: 322px;
		height: 395px;
		right: 0;
		top: 0;
	}

	.TaroBg-right-column {
		width: 52px;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
	}

	// top
	.TaroBg-top {
		height: 17px;
		width: 100%;
		
	}

	.TaroBg-flying-bird {
		position: absolute;
		width: 252px;
		height: 206px;
		top: 25%;
		right: 25%;
	}

	@media (min-width: 1200px) and (max-width: 1399px){
		.TaroBg-left-bottom {
			width: 475.2px;
			height: 362px;
			background-size: contain !important;
		}
	
		.TaroBg-left-column {
			width: 50.5px;
			background-size: contain !important;
		}
	
		.TaroBg-left-top {
			width: 306px;
			height: 355.5px;
			background-size: contain !important;
		}
	
		// right
		.TaroBg-right-bottom {
			width: 473.5px;
			height: 370px;
			background-size: contain !important;
		}
	
		.TaroBg-right-top {
			width: 290px;
			height: 355.5px;
			background-size: contain !important;
		}
	
		.TaroBg-right-column {
			width: 47px;
			background-size: contain !important;
		}
	
		// top
		.TaroBg-top {
			height: 7px;
		}

		.TaroBg-flying-bird {
			width: 227px;
			height: 185.5px;
			top: 10%;
			background-size: contain !important;
		}
	}

	@media (min-width: 993px) and (max-width: 1199px) {
		.TaroBg-left-bottom {
			width: 396px;
			height: 301px;
			background-size: contain !important;
		}
	
		.TaroBg-left-column {
			width: 42px;
			background-size: contain !important;
		}
	
		.TaroBg-left-top {
			width: 255px;
			height: 295.5px;
			background-size: contain !important;
		}
	
		// right
		.TaroBg-right-bottom {
			width: 394.5px;
			height: 308px;
			background-size: contain !important;
		}
	
		.TaroBg-right-top {
			width: 241px;
			height: 295px;
			background-size: contain !important;
		}
	
		.TaroBg-right-column {
			width: 39px;
			background-size: contain !important;
		}
	
		// top
		.TaroBg-top {
			height: 6px;
		}

		.TaroBg-flying-bird {
			width: 189px;
			height: 154.5px;
			top: 10%;
			background-size: contain !important;
		}
	}

	@media (min-width: 577px) and (max-width: 992px) {
		.TaroBg-left-bottom {
			width: 264px;
			height: 201px;
			background-size: contain !important;
		}
	
		.TaroBg-left-column {
			width: 28px;
			background-size: contain !important;
		}
	
		.TaroBg-left-top {
			width: 170px;
			height: 197.5px;
			background-size: contain !important;
		}
	
		// right
		.TaroBg-right-bottom {
			width: 263px;
			height: 205.5px;
			background-size: contain !important;
		}
	
		.TaroBg-right-top {
			width: 161px;
			height: 197.5px;
			background-size: contain !important;
		}
	
		.TaroBg-right-column {
			width: 26px;
			background-size: contain !important;
		}
	
		// top
		.TaroBg-top {
			height: 4px;
		}

		.TaroBg-flying-bird {
			width: 126px;
			height: 103px;
			top: 10%;
			background-size: contain !important;
		}
	}

	@media (max-width: 576px) {
		.TaroBg-left-bottom {
			width: 132px;
			height: 100px;
			background-size: contain !important;
		}
	
		.TaroBg-left-column {
			width: 13px;
			background-size: contain !important;
		}
	
		.TaroBg-left-top {
			width: 85px;
			height: 99px;
			background-size: contain !important;
		}
	
		// right
		.TaroBg-right-bottom {
			width: 131px;
			height: 102px;
			background-size: contain !important;
		}
	
		.TaroBg-right-top {
			width: 80px;
			height: 99px;
			background-size: contain !important;
		}
	
		.TaroBg-right-column {
			width: 13px;
			background-size: contain !important;
		}
	
		// top
		.TaroBg-top {
			height: 4px;
		}

		.TaroBg-flying-bird {
			width: 126px;
			height: 103px;
			top: 10%;
			background-size: contain !important;
		}
	}
}