.App {
	text-align: center;
	font-family: "Inter", sans-serif;
	font-style: normal;
	position: relative;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	font-family: "Philosopher", serif;
}

.language-selector {
	position: absolute;
    top: 15px;
    right: 15px;
}


.loading {
	position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

	.loading-text {
		text-align: center;
        padding: 0 0 40px 0;
        font-family: "Philosopher", sans-serif;
        font-weight: 600;
        font-size: 42px;
        color: white;
        text-shadow: 0 0 5px BLACK;
        text-transform: uppercase;
        margin-left: 60px;

		@media (max-width: 576px) {
			font-size: 24px;
		}
	}

	/* https://codepen.io/vineethtrv/pen/NWxZqMM */
	.loader-47 {
		width : 48px;
		height: 48px;
		display: inline-block;
		position: relative;

		> div {
			/* position: absolute;
			top: 0; */
		}

		.square-1,
		.square-2 {
			display: block;
            position: absolute;
			top: 0;
			left:0;
			width : 80px;
			height: 80px;
			border: 5px solid #FFF;
			animation: rotation 2s ease-in-out infinite;
		}
		.square-2 {
			border-color: #f3df88;
			animation-delay: 1s;
		}
	}
}

@keyframes rotation {
	0% { transform: rotate(0deg) }
	100% { transform: rotate(360deg) }
}

.tarot-app.bootstrap-wrapper {
	height: 100vh;
	font-family: Arial, sans-serif;
	overflow: hidden;

	.container {
		height: 100vh;

		> .row {
			height: 100%;
		}
	}

	.taro-form {
		position: relative;
		padding-left: 0;
		padding-right: 0;
		@media (max-width: 576px) {
			margin-left: -25px;
		}
		@media (min-width: 577px) and (max-width: 992px) {}
		@media (min-width: 993px) and (max-width: 1199px) {}
		@media (min-width: 1200px) and (max-width: 1399px){}
		@media (min-width: 1400px) {}

		.form-content {
			position: relative;
			z-index: 1;
			padding: 20px 10px 50px 10px;
			@media (max-width: 576px) {
			}
			@media (min-width: 577px) and (max-width: 992px) {
				padding: 20px 20px 50px 20px;
			}
			@media (min-width: 993px) and (max-width: 1199px) {
				padding: 20px 30px 70px 30px;
			}
			@media (min-width: 1200px) and (max-width: 1399px) {
				padding: 30px 50px 80px 50px;
			}
			@media (min-width: 1400px) {
				padding: 30px 50px 80px 50px;
			}

			.form-title {
				margin-bottom: 40px;
					h1 {
						font-size: 54px;
					}

				@media (max-width: 576px) {
					margin-bottom: 20px;
					h1 {
						font-size: 42px;
					}
				}
				@media (min-width: 1400px) {
					margin-bottom: 30px;
					padding-top: 20px;
				}
			}

			.form-block {
				padding: 0 15px;

				form {
					display: flex;
					flex-direction: column;
					align-items: center;
		
					.faqSelector {
						width: 100%;
					
						.faqSelector__title {
							color: #333;
							text-align: left;
						}
					
						select {
							background-color: #fff6e7;
							border: 1px solid #ccc;
							border-radius: 5px;
							margin-bottom: 10px;
							padding: 5px 10px;
							width: 100%;
							/* min-width: 320px;
							max-width: 320px; */
						}
					}

					textarea {
						width: calc(100% - 20px);
						height: 100px;
						padding: 10px;

						background-color: #fff6e7;
						border: 1px solid #ccc;
						border-radius: 10px;
						margin-bottom: 30px;
						resize: none;

						font-size: 16px;
					}
					  
					button {
						padding: 10px 20px;
						font-size: 16px;
						cursor: pointer;

						/* min-width: 200px; */
						/* background: rgb(253 200 9);
						background: linear-gradient(133deg, rgb(253 212 9) 0%, rgb(187 128 0) 50%, rgb(253 189 9) 100%); */
						color: white;
						border-radius: 50px;
						font-size: 18px;
						text-transform: uppercase;
						transition: all .2s;
					}
				}
			}
		}
		

		> div {
			position: absolute;
		}

		/* Dsign elements */
		.form-bg {
			width: 100%;
			height: 100%;
		}

		.form-left {
			width: 37px;
			height: 100%;
			left: 0;
		}
		.form-righ {
			width: 37px;
			height: 100%;
			right: 0;
		}
		.form-top {
			width: 100%;
			height: 19px;
			top: 0;
		}
		.form-top-left {
			width: 293px;
			height: 353px;
			left: -132px;
			top: -80px;
		}
		.form-top-right {
			width: 292px;
			height: 351px;
			right: -115px;
			top: -76px;
		}
		.form-top-middle {
			width: 79px;
			height: 79px;
			left: 50%;
			top: -35px;
			margin-left: -50px;
		}
		.form-bottom {
			width: 100%;
			height: 22px;
			bottom: 0;
		}
		.form-bottom-left {
			width: 252px;
			height: 229px;
			left: -108px;
			bottom: -37px;
		}
		.form-bottom-right {
			width: 238px;
			height: 236px;
			right: -92px;
			bottom: -43px;
		}
		.form-bottom-middle {
			width: 285px;
			height: 194px;
			left: 50%;
			bottom: -137px;
			transform: translate(-50%, 0);
		}

		@media (min-width: 1200px) and (max-width: 1399px){
			/* X 0.9 */
			.form-top-left {
				width: 263.7px;
				height: 317.7px;
				left: -107px;
                top: -72px;
				background-size: contain !important;
			}

			.form-top-right {
				width: 263px;
				height: 317px;
				right: -104px;
                top: -69px;
				background-size: contain !important;
			}

			.form-bottom-right {
				width: 216px;
				height: 212.5px;
				right: -85px;
                bottom: -37px;
				background-size: contain !important;
			}

			.form-bottom-left {
				width: 226.8px;
				height: 205.2px;
				left: -97px;
                bottom: -32px;
				background-size: contain !important;
			}

			.form-bottom {
				height: 21.6px;
				background-size: contain !important;
			}

			.form-top {
				height: 18px;
				background-size: contain !important;
			}

			.form-righ {
				width: 32.4px;
				background-size: contain !important;
			}

			.form-left {
				width: 32.4px;
				background-size: contain !important;
			}
		
			.form-top-middle {
				width: 60px;
				height: 60px;
				left: 50%;
				top: -30px;
				margin-left: -30px;
				background-size: contain !important;
			}

			.form-bottom-middle {
				width: 213.75px;
           		height: 145.5px;
				left: 50%;
				bottom: -96px;
				transform: translate(-50%, 0);	
				background-size: contain !important;
			}
		}

		@media (min-width: 993px) and (max-width: 1199px) {
			/* X 0.75 */

			.form-top-left {
				width: 146.5px;
				height: 176.5px;
				left: -67px;
                top: -40px;
				background-size: contain !important;
			}

			.form-top-right {
				width: 146px;
				height: 176px;
				right: -58px;
                top: -38px;
				background-size: contain !important;
			}

			.form-bottom-right {
				width: 120px;
				height: 118px;
				right: -47px;
                bottom: -21px;
				background-size: contain !important;
			}

			.form-bottom-left {
				width: 126px;
				height: 114px;
				left: -54px;
                bottom: -18px;
				background-size: contain !important;
			}

			.form-bottom {
				height: 12px;
				background-size: contain !important;
			}

			.form-top {
				height: 10px;
				background-size: contain !important;
			}

			.form-righ {
				width: 18px;
				background-size: contain !important;
			}

			.form-left {
				width: 18px;
				background-size: contain !important;
			}
		
			.form-top-middle {
				width: 60px;
				height: 60px;
				left: 50%;
				top: -30px;
				margin-left: -30px;
				background-size: contain !important;
			}

			.form-bottom-middle {
				width: 213.75px;
           		height: 145.5px;
				left: 50%;
				bottom: -96px;
				transform: translate(-50%, 0);	
				background-size: contain !important;
			}
		}

		@media (min-width: 577px) and (max-width: 992px) {
			/* X 0.5 */
			.form-top-left {
				width: 146.5px;
				height: 176.5px;
				left: -67px;
                top: -40px;
				background-size: contain !important;
			}

			.form-top-right {
				width: 146px;
				height: 176px;
				right: -58px;
                top: -38px;
				background-size: contain !important;
			}

			.form-bottom-right {
				width: 120px;
				height: 118px;
				right: -47px;
                bottom: -21px;
				background-size: contain !important;
			}

			.form-bottom-left {
				width: 126px;
				height: 114px;
				left: -54px;
                bottom: -18px;
				background-size: contain !important;
			}

			.form-bottom {
				height: 12px;
				background-size: contain !important;
			}

			.form-top {
				height: 10px;
				background-size: contain !important;
			}

			.form-righ {
				width: 18px;
				background-size: contain !important;
			}

			.form-left {
				width: 18px;
				background-size: contain !important;
			}
		
			.form-top-middle {
				width: 60px;
				height: 60px;
				left: 50%;
				top: -30px;
				margin-left: -30px;
				background-size: contain !important;
			}

			.form-bottom-middle {
				width: 213.75px;
           		height: 145.5px;
				left: 50%;
				bottom: -96px;
				transform: translate(-50%, 0);	
				background-size: contain !important;
			}
		}

		@media (max-width: 576px) {
			/* X 0.25 */
			.form-top-left {
				width: 73px;
				height: 88px;
				left: -33px;
				top: -20px;
				background-size: contain !important;
			}

			.form-top-right {
				width: 73px;
				height: 88px;
				right: -29px;
				top: -19px;
				background-size: contain !important;
			}

			.form-bottom-right {
				width: 60px;
				height: 59px;
				right: -23px;
				bottom: -11px;
				background-size: contain !important;
			}

			.form-bottom-left {
				width: 63px;
				height: 57px;
				left: -27px;
				bottom: -9px;
				background-size: contain !important;
			}

			.form-bottom {
				height: 6px;
				background-size: contain !important;
			}

			.form-top {
				height: 5px;
				background-size: contain !important;
			}

			.form-righ {
				width: 9px;
				background-size: contain !important;
			}

			.form-left {
				width: 9px;
				background-size: contain !important;
			}
		
			.form-top-middle {
				width: 50px;
				height: 50px;
				left: 50%;
				top: -25px;
				margin-left: -25px;
				background-size: contain !important;
			}

			.form-bottom-middle {
				width: 200px;
				height: 136px;
				left: 50%;
				bottom: -96px;
				transform: translate(-50%, 0);	
				background-size: contain !important;
			}		
		}
	}
}

.gold {
	color: #FFFFFF;
	text-shadow: 0 2px 3px rgba(0, 0, 0, 1);
	background-image: radial-gradient(300px at 50% -50%, rgb(229, 184, 99) 0%, rgb(220, 175, 93) 5%, rgb(139, 96, 42) 48%, rgb(103, 61, 19) 67%, rgb(165, 121, 58) 83%, rgb(199, 154, 80) 92%, rgb(222, 177, 95) 98%, rgb(227, 181, 97) 99%, rgb(231, 185, 100) 100%);
}
.gold:hover {
	background-image: radial-gradient(300px at 50% -50%, rgb(247, 223, 146) 0%, rgb(240, 214, 138) 5%, rgb(178, 133, 65) 48%, rgb(151, 98, 33) 67%, rgb(196, 157, 85) 83%, rgb(222, 190, 114) 92%, rgb(239, 212, 134) 98%, rgb(242, 216, 137) 99%, rgb(245, 220, 141) 100%);
}

.gold:disabled {
	background-color: dimgrey;
	background-image: none;
	color: linen;
}


.popup-wrap {
  width:100%;
  height:100%;
  position:fixed;
  top:0px;
  left:0px;
  content:'';
  background:rgba(0,0,0,0.5);

	.close-btn {
		display: block;
        position: absolute;
        right: 25px;
        top: -75px;
		width: 40px;
        height: 40px;
        border: none;
        background: none;
        margin: 100px auto;
        transform: rotate(45deg) scale(1.5);
        z-index: 3;

		.top,
		.bot {
			display: block;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			animation-timing-function: cubic-bezier(1.000, 0.000, 0.000, 1.000);
			animation-iteration-count: 1;
		}

		.top {
			height: 100%;
			width: 2px;
			margin-left: -1px;
			transform: skewY(45deg);
			
			top: 0;
		}

		.bot {
			width: 100%;
			height: 2px;
			margin-top: -1px;
			transform: skewX(-45deg);
			
			left: 0;
		}

		&:hover {
			cursor: pointer;
			
			.top {
				animation: top-out .25s forwards, top-in .25s forwards .25s ;
			}
			.bot {
				animation: bot-out .25s forwards .1s, bot-in .25s forwards .35s ;
			}
		  }
	}
}
.popup-box {
	>.container {
		>.row {
			height: 100%;
			flex-direction: column;
		}
	} 
}
.popup-box {
	position:absolute;
	width: 100%;
	max-width: 1200px;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%) scale(0.5);
	border-radius:3px;
	text-align:center;
	color: #fff;
	text-shadow: 0 0 5px black;

	.popupTitle {
		font-size: 32px;
		margin-bottom: 10px;
		font-family: "Philosopher", serif;
		font-weight: 600;
		font-style: normal;
	}

	.hide-cards {
		padding: 15px 24px;
		font-size: 24px;
		cursor: pointer;
		border-radius: 50px;
		text-transform: uppercase;
	}

	.cards_footer {
		padding: 10px;

		.Separator {
			margin-bottom: 10px;
            margin-top: 0;
		}
	}
}

.transform-in, .transform-out {
  display:block;
  -webkit-transition:all ease 0.5s;
  transition:all ease 0.5s;
}

.transform-in {
	-webkit-transform:translate(-50%, -50%) scale(1);
	transform:translate(-50%, -50%) scale(1);
}

.transform-out {
	-webkit-transform:translate(-50%, -50%) scale(0.5);
	transform:translate(-50%, -50%) scale(0.5);
}

.fadeOut {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.fadeIn {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.2s linear;
}


@keyframes top-out {
	from {
		top: 0%;
		height: 100%;
	}
	to {
		top: 200%;
		height: 0%;
	}
}
  
@keyframes top-in {
	from {
		top: -100%;
		height: 0%;
	}
	to {
		top: 0%;
		height: 100%;
	}
}
  
@keyframes bot-out {
	from {
		left: 0%;
		width: 100%;
	}
	to {
		left: 200%;
		width: 0%;
	}
}
  
@keyframes bot-in {
	from {
		left: -100%;
		width: 0%;
	}
	to {
		left: 0%;
		width: 100%;
	}
}






/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
	position: relative;
	z-index: 2;
	cursor: pointer;
}
  
/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
	visibility: hidden;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
	opacity: 0;
	pointer-events: none;
}
  
/* Position tooltip above the element */
[data-tooltip]:before {
	position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -160px;
    padding: 10px 15px;
    width: 300px;
    border-radius: 7px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 16px;
    font-family: "Inter", sans-serif;
	font-weight: 300;
    font-style: normal;
    line-height: 1.2;
}
  
/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
    position: absolute;
    bottom: calc(100% - 5px);
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #000;
    border-top: 10px solid hsla(0, 0%, 20%, 0.9);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
}
  
/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
	visibility: visible;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
	opacity: 1;
}