@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.philosopher-regular {
	font-family: "Philosopher", sans-serif;
	font-weight: 400;
	font-style: normal;
}
  
.philosopher-bold {
	font-family: "Philosopher", sans-serif;
	font-weight: 700;
	font-style: normal;
}
  
.philosopher-regular-italic {
	font-family: "Philosopher", sans-serif;
	font-weight: 400;
	font-style: italic;
}
  
.philosopher-bold-italic {
	font-family: "Philosopher", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.inter {
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
}
  


/* Spectral
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.spectral-extralight {
	font-family: "Spectral", serif;
	font-weight: 200;
	font-style: normal;
}
  
.spectral-light {
	font-family: "Spectral", serif;
	font-weight: 300;
	font-style: normal;
}
  
.spectral-regular {
	font-family: "Spectral", serif;
	font-weight: 400;
	font-style: normal;
}
  
.spectral-medium {
	font-family: "Spectral", serif;
	font-weight: 500;
	font-style: normal;
}
  
.spectral-semibold {
	font-family: "Spectral", serif;
	font-weight: 600;
	font-style: normal;
}
  
.spectral-bold {
	font-family: "Spectral", serif;
	font-weight: 700;
	font-style: normal;
}
  
.spectral-extrabold {
	font-family: "Spectral", serif;
	font-weight: 800;
	font-style: normal;
}
  
.spectral-extralight-italic {
	font-family: "Spectral", serif;
	font-weight: 200;
	font-style: italic;
}
  
.spectral-light-italic {
	font-family: "Spectral", serif;
	font-weight: 300;
	font-style: italic;
}
  
.spectral-regular-italic {
	font-family: "Spectral", serif;
	font-weight: 400;
	font-style: italic;
}
  
.spectral-medium-italic {
	font-family: "Spectral", serif;
	font-weight: 500;
	font-style: italic;
}
  
.spectral-semibold-italic {
	font-family: "Spectral", serif;
	font-weight: 600;
	font-style: italic;
}
  
.spectral-bold-italic {
	font-family: "Spectral", serif;
	font-weight: 700;
	font-style: italic;
}
  
.spectral-extrabold-italic {
	font-family: "Spectral", serif;
	font-weight: 800;
	font-style: italic;
}
 */


/* Open Sans 
.open-sans {
	font-family: "Open Sans", sans-serif;
	font-style: normal;
}
*/