* {
	margin: 0;
	padding: 0;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}


  
h1 {
	color: #333;
}

  
.response {
	margin-top: 20px;
	font-size: 18px;
	color: #555;
	white-space: pre-wrap;
	text-align: center;
}
  