.Separator {
	display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 20px;

	.Seperator_hr {
		border-bottom: 1px solid #757575;
		margin: 7px 0;
		opacity: .26;
		flex: 1 1;
	}

	.Seperator_img {
		margin: 0 10px 0px;
	}
}