.popupCards {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	// padding-bottom: 100px;
	// height: 100vh;

	.popupConclusion {
		font-size: 24px;
		font-family: "Inter", sans-serif;
		font-weight: 300;
		color: white;
		text-shadow: 0 0 5px BLACK;
	
		.ArticlePullQuote {
			display: block;
			width: 70px;
			height: 70px;
			margin: 0 auto;
		}
	}

	.popupCardsList {
		display: flex;
		width: 100%;
		justify-content: space-around;

		.rotate-container {
			position: relative;
		
			.cardImage {	
				img {
					margin: 0 auto;
					display: block;
					max-width: 255px;
				}
			}
			.card-front {
				width: 100%;
				height: 100%;
				transform: perspective(600px) rotateY(0deg);
				backface-visibility: hidden;
				transition: all .5s linear 0s;
		
				.cardImage {
					padding-top: 35px;
					height: auto;
					
					img {
						border-radius: 15px;
						border: 1px solid #c69a4f;
					}
				}
			}
		
			.card-back {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				height: 100%;
				transform: perspective(600px) rotateY(0deg);
				backface-visibility: hidden;
				transition: all .5s linear 0s;
				
		
				transform: perspective(1600px) rotateY(180deg);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
		
			.rotate-card-front {
				transform: perspective(1600px) rotateY(-180deg)
			}
		
			.rotate-card-back {
				transform: perspective(1600px) rotateY(0deg)
			}
		}
		

		.card_wrapper {
			.cardBlock {
				// margin: 0 20px;
			
				.cardTitle {
					font-size: 24px;
					margin-bottom: 10px;
					font-family: "Philosopher", sans-serif;
					font-weight: 400;
					font-style: normal;
					color: white;
					text-shadow: 0 0 5px black;
			
					span {
						white-space: nowrap;
						display: inline-block;
						position: relative;
					}
			
					.tooltip_img {
						display: block;
						width: 20px;
						height: 20px;
						transform: translate(5px, 5px);
						position: absolute;
						top: 0;
						right: -20px;
					}
				}
			
				.cardImage {
					border: 15px solid #fff;
					border-radius: 10px;
					max-height: 100%;
					
					img {
						// max-width: 100%;
						border: 1px solid black;
						max-height: 100%;
					}
				}
			
				.cardConclusion {
					padding: 15px 10px 0;
					border-radius: 5px;
					// max-height: 115px;
					min-height: 120px;
					max-height: 120px;
					overflow: auto;
					// padding-top: 10px;
					// margin: 10px -40px;
			
					font-size: 15px;
					font-family: "Inter", sans-serif;
					font-weight: 300;
					color: white;
					text-shadow: 0 0 5px black;
			
				}
			}
		}
	}
}